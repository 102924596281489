
import { defineComponent, ref, onMounted, computed } from "vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import MasterService from "@/core/services/car/MasterService";
import { roles } from "@/store/stateless/store";

interface NotificationBodyParam {
  per_page: string;
}

export default defineComponent({
  name: "contact-us-list",
  components: {
    // InvoiceAction,
    // Overlay,
  },
  data() {
    return {
      // opened: false,
      // visible: false,
    };
  },
  setup() {
    // let customers_obj = ref([]);
    const store = useStore();
    let bodyParameters = {} as NotificationBodyParam;
    let exportUrl = ref<string>("");
    bodyParameters = {
      per_page: "50",
    };

    onMounted(() => {
      if(!roles.inquireContactUs.includes(roles.userId())) window.location.href = '/#/404'
      
      
      MenuComponent.reinitialization();

      setCurrentPageBreadcrumbs("Contact Us List", ["Inquires"]);
      // API Action to get customers list
      getContactUs(bodyParameters);
    });

    const getPaginationUrl = (index) => {
      let pagenationUrl = index.split("=");
      MasterService.getContactUs(bodyParameters, pagenationUrl[1]);
    };
    const onPerPageChange = (event) => {
      bodyParameters.per_page = event.target.value;
      getContactUs(bodyParameters);
    };

    const exportContactUsRequest = () => {
      Promise.all([MasterService.exportContactUsRequest()]).then((data) => {
        let exportData = computed(() => {
          return store.getters.getExportData;
        });
        exportUrl.value = exportData.value;
      });
    };

    let disableSaveButton = computed(() => {
      return store.getters.getDisableButtonStatus;
    });

    const getContactUs = (bodyParameters) => {
      console.log(bodyParameters);
      MasterService.getContactUs({ per_page: 50 }, 1);
    };

    const search = ref<string>("");

    return {
      roles,
      search,
      onPerPageChange,
      getPaginationUrl,
      bodyParameters,
      exportUrl,
      disableSaveButton,
      exportContactUsRequest,
    };
  },
});
